import types from '@/store/collectionsModule/common/types.js'

const mutations = {
  [types.SET_BROWSERS]: (state, browsers) => {
    state.browsers = browsers
  },
  [types.SET_BROWSERS_ARE_LOADING]: (state, browsersAreLoading) => {
    state.browsersAreLoading = browsersAreLoading
  },
  [types.SET_CARRIERS]: (state, carriers) => {
    state.carriers = carriers
  },
  [types.SET_CARRIERS_ARE_LOADING]: (state, carriersAreLoading) => {
    state.carriersAreLoading = carriersAreLoading
  },
  [types.SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  },
  [types.SET_CATEGORIES_ARE_LOADING]: (state, categoriesAreLoading) => {
    state.categoriesAreLoading = categoriesAreLoading
  },
  [types.SET_CATEGORY_GROUPS]: (state, categoryGroups) => {
    state.categoryGroups = categoryGroups
  },
  [types.SET_CONVERSION_TYPES]: (state, conversionTypes) => {
    state.conversionTypes = conversionTypes
  },
  [types.SET_IN_PAGE_SKIN_GROUPS]: (state, inPageSkinGroups) => {
    state.inPageSkinGroups = inPageSkinGroups
  },
  [types.SET_SPOTS]: (state, spots) => {
    state.spots = spots
  },
  [types.SET_CATEGORY_GROUPS_ARE_LOADING]: (state, categoryGroupsAreLoading) => {
    state.categoryGroupsAreLoading = categoryGroupsAreLoading
  },
  [types.SET_COUNTRIES]: (state, countries) => {
    state.countries = countries
  },
  [types.SET_COUNTRIES_ARE_LOADING]: (state, countriesAreLoading) => {
    state.countriesAreLoading = countriesAreLoading
  },
  [types.SET_DEVICE_MODELS]: (state, deviceModels) => {
    state.deviceModels = deviceModels
  },
  [types.SET_DEVICE_MODELS_ARE_LOADING]: (state, deviceModelsAreLoading) => {
    state.deviceModelsAreLoading = deviceModelsAreLoading
  },
  [types.SET_IP2_LOCATION_TYPES]: (state, ip2LocationTypes) => {
    state.ip2LocationTypes = ip2LocationTypes
  },
  [types.SET_IP2_LOCATION_TYPES_ARE_LOADING]: (state, ip2LocationTypesAreLoading) => {
    state.ip2LocationTypesAreLoading = ip2LocationTypesAreLoading
  },
  [types.SET_LANGUAGES]: (state, languages) => {
    state.languages = languages
  },
  [types.SET_LANGUAGES_ARE_LOADING]: (state, languagesAreLoading) => {
    state.languagesAreLoading = languagesAreLoading
  },
  [types.SET_OPERATING_SYSTEMS]: (state, operatingSystems) => {
    state.operatingSystems = operatingSystems
  },
  [types.SET_OPERATING_SYSTEMS_ARE_LOADING]: (state, operatingSystemsAreLoading) => {
    state.operatingSystemsAreLoading = operatingSystemsAreLoading
  },
  [types.SET_TRAFFIC_TYPES]: (state, val) => {
    state.trafficTypes = val
  },
  [types.SET_TRAFFIC_TYPES_ARE_LOADING]: (state, trafficTypesAreLoading) => {
    state.trafficTypesAreLoading = trafficTypesAreLoading
  },
  [types.SET_DEVICE_VENDORS]: (state, val) => {
    state.deviceVendors = val
  },
  [types.SET_DEVICE_VENDORS_ARE_LOADING]: (state, deviceVendorsAreLoading) => {
    state.deviceVendorsAreLoading = deviceVendorsAreLoading
  },
  [types.SET_INTERESTS]: (state, interests) => {
    state.interests = interests
  },
  [types.SET_INTERESTS_ARE_LOADING]: (state, interestsAreLoading) => {
    state.interestsAreLoading = interestsAreLoading
  },
  [types.SET_MESSENGERS]: (state, messengers) => {
    state.messengers = messengers
  },
  [types.SET_MESSENGERS_ARE_LOADING]: (state, messengersAreLoading) => {
    state.messengersAreLoading = messengersAreLoading
  },
  [types.SET_CONVERSION_TYPES_ARE_LOADING]: (state, conversionTypesAreLoading) => {
    state.conversionTypesAreLoading = conversionTypesAreLoading
  },
  [types.SET_IN_PAGE_SKIN_GROUPS_ARE_LOADING]: (state, inPageSkinGroupsAreLoading) => {
    state.inPageSkinGroupsAreLoading = inPageSkinGroupsAreLoading
  },
  [types.SET_SPOTS_ARE_LOADING]: (state, spotsAreLoading) => {
    state.spotsAreLoading = spotsAreLoading
  }
}

export default mutations
