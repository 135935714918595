<template>
  <v-list>
    <v-list-item class="d-flex justify-center align-center" @click="redirectToDashboard()">
      <v-img min-width="163" max-width="165" :src="getLogo" class="px-1 mx-3 mt-5 mb-7" />
    </v-list-item>

    <iterable-sidebar-item
      v-for="(item, index) in generalMenu"
      :key="`general-menu-${index}`"
      :menu-item="item"
      @item-click="sendGtm(item)"
    />

    <widget-banner v-show="!isMiniSidebarShown" />

    <iterable-sidebar-item
      v-for="(item, index) in helpMenu"
      :key="`help-menu-${index}`"
      :menu-item="item"
    />
  </v-list>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import IterableSidebarItem from '@/components/main/Sidebar/IterableSidebarItem.vue'
  import WidgetBanner from '@/components/main/Sidebar/WidgetBanner.vue'
  import routeNames from '@/types/route-names.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'MenuBar',
    components: {
      IterableSidebarItem,
      WidgetBanner
    },
    data() {
      return {
        redesignedSidebarIsEnabled: localStorage.ab_test_sidebar_redesign
      }
    },
    computed: {
      ...mapState('settings', ['isMiniSidebarShown', 'adNetwork']),
      ...mapGetters('settings', ['getMainRouteName', 'getAdNetworkImages']),
      getLogo() {
        return this.isMiniSidebarShown
          ? this.getAdNetworkImages.small_logo
          : this.getAdNetworkImages.theme_black_logo
      },
      notificationSidebarItem() {
        return {
          text: this.$t('main.sidebar.notification'),
          page: routeNames.NOTIFICATION,
          activeOn: ['Notification'],
          isUnreadNotifications: true,
          isDivided: this.redesignedSidebarIsEnabled,
          icon: '$bells'
        }
      },
      generalMenu() {
        const menu = [
          {
            text: this.$t('main.create_campaign'),
            page: routeNames.CREATE_CAMPAIGN,
            activeOn: ['CreateCampaign'],
            isDedicatedLink: true,
            gtmEvent: this.redesignedSidebarIsEnabled
              ? GTM_EVENTS.AB_TEST_CREATE_CAMPAIGN_FROM_REDESIGNED_SIDEBAR
              : GTM_EVENTS.CREATE_CAMPAIGN_FROM_DEFAULT_SIDEBAR,
            icon: this.redesignedSidebarIsEnabled && !this.isMiniSidebarShown
              ? ''
              : '$plus-in-ring',
            ...this.redesignedSidebarIsEnabled && {
              isMajor: true,
              isDivided: true
            }
          },
          {
            ...!this.redesignedSidebarIsEnabled && this.notificationSidebarItem
          },
          {
            text: this.$t('main.sidebar.dashboard'),
            page: routeNames.DASHBOARD,
            activeOn: ['Dashboard'],
            icon: '$dashboard'
          },
          {
            text: this.$t('main.sidebar.statistics'),
            page: routeNames.CAMPAIGN_STATISTICS,
            activeOn: ['CampaignsStatistics'],
            icon: '$statistics'
          },
          {
            text: this.$t('main.sidebar.ads'),
            page: routeNames.ADS,
            activeOn: ['Ads'],
            icon: '$sound'
          },
          {
            text: this.$t('main.sidebar.campaigns'),
            page: routeNames.CAMPAIGNS,
            activeOn: ['Campaigns'],
            icon: '$lightning'
          },
          {
            text: this.$t('main.sidebar.traffic_dsp'),
            page: routeNames.PROVIDERS,
            activeOn: ['Providers'],
            icon: '$earth-search'
          },
          {
            text: this.$t('main.sidebar.marketplace'),
            page: routeNames.MARKETPLACE,
            activeOn: ['Marketplace'],
            icon: '$cart'
          },
          {
            text: this.$t('main.sidebar.traffic_chart'),
            page: routeNames.TRAFFIC_CHART,
            activeOn: ['TrafficChart'],
            icon: '$file'
          },
          {
            text: this.$t('main.sidebar.tracking'),
            page: routeNames.TRACKING,
            activeOn: ['Tracking'],
            isDivided: !this.redesignedSidebarIsEnabled,
            icon: '$chart'
          },
          {
            ...this.redesignedSidebarIsEnabled && this.notificationSidebarItem
          },
          {
            text: this.$t('main.sidebar.finances'),
            page: routeNames.FINANCES,
            activeOn: ['Finances'],
            icon: '$dollar'
          },
          {
            text: this.$t('main.sidebar.add_funds'),
            page: routeNames.ADD_FUNDS,
            activeOn: ['AddFunds'],
            isDivided: true,
            icon: '$credit-card'
          }
        ]
        return this.filterMenu(menu)
      },
      helpMenu() {
        const menu = [
          {
            text: this.$t('main.sidebar.api'),
            page: routeNames.API,
            activeOn: ['Api'],
            icon: '$api'
          },
          {
            text: this.$t('main.sidebar.referral_program'),
            page: 'Personal',
            activeOn: [],
            icon: '$user-import'
          },
          {
            text: this.$t('main.sidebar.rtb'),
            page: routeNames.RTB,
            activeOn: [
              'Rtb',
              'RtbEndpoints',
              'RtbCreateEndpoint',
              'RtbEditEndpoint',
              'RtbNetwork',
              'RtbCreateNetwork'
            ],
            icon: '$rtb'
          },
          {
            text: this.$t('main.sidebar.rtb_statistics'),
            page: routeNames.RTB_STATISTICS,
            activeOn: ['RtbStatistics'],
            icon: '$statistics'
          },
          {
            text: this.$t('main.sidebar.ssp_statistics'),
            page: routeNames.SSP_STATISTICS,
            activeOn: ['SspStatistics'],
            icon: '$statistics'
          },
          {
            text: this.$t('main.sidebar.blog'),
            href: this.adNetwork.settings.socials.blog,
            activeOn: [],
            isDivided: true,
            icon: '$blog',
            isShown: !!this.adNetwork.settings.socials.blog
          }
        ]
        return this.filterMenu(menu)
      },
      routes() {
        return this.$router.options.routes
      }
    },
    created() {
      gtmPush({
        event: this.redesignedSidebarIsEnabled
          ? GTM_EVENTS.AB_TEST_SHOWN_REDESIGNED_SIDEBAR
          : GTM_EVENTS.DEFAULT_SIDEBAR_SHOWN
      })
    },
    methods: {
      sendGtm({ gtmEvent }) {
        if (!gtmEvent) return

        gtmPush({ event: gtmEvent })
      },
      filterMenu(menu) {
        return menu.filter((menuItem) => {
          if (!menuItem.page) return menuItem.isShown

          const foundRoute = this.routes.find((route) => route.name === menuItem.page)

          if (foundRoute.meta.section) {
            return this.adNetwork.guardSections.some(({ name }) => name === foundRoute.meta.section)
          }
          return true
        })
      },
      redirectToDashboard() {
        if (this.$route.name === this.getMainRouteName) return

        this.$router.push({ name: this.getMainRouteName })
      }
    }
  }
</script>
