export default {
  SET_AD_ID: 'SET_AD_ID',
  SET_NAME: 'SET_NAME',
  SET_BRAND: 'SET_BRAND',
  SET_URL: 'SET_URL',
  SET_All_ERRORS: 'SET_All_ERRORS',
  SET_VALIDATION_WARNINGS: 'SET_VALIDATION_WARNINGS',
  SET_ERRORS_NAME: 'SET_ERRORS_NAME',
  SET_SAVE_IS_LOADING: 'SET_SAVE_IS_LOADING',
  SET_SIZE: 'SET_SIZE',
  SET_ROTATION_TYPE: 'SET_ROTATION_TYPE',
  SET_TYPE: 'SET_TYPE',
  SET_CODE: 'SET_CODE',
  SET_HOURS_THRESHOLD: 'SET_HOURS_THRESHOLD',
  SET_IMPRESSIONS_THRESHOLD: 'SET_IMPRESSIONS_THRESHOLD',
  SET_CREATIVES: 'SET_CREATIVES',
  SET_COMMON_URL: 'SET_COMMON_URL',
  SET_SKIP_TIME: 'SET_SKIP_TIME',
  SET_MOTION_BANNER_PARAMS: 'SET_MOTION_BANNER_PARAMS',
  SET_TITLE: 'SET_TITLE',
  SET_ICON: 'SET_ICON',
  SET_CONTENT_FEED_NAME: 'SET_CONTENT_FEED_NAME',
  SET_CONTENT_FEED_URL: 'SET_CONTENT_FEED_URL',
  SET_PROVIDER_WEBSITE: 'SET_PROVIDER_WEBSITE',
  SET_AD_FORMAT_TYPE: 'SET_AD_FORMAT_TYPE',
  SET_CREATIVE_TOKENS: 'SET_CREATIVE_TOKENS',
  SET_IS_DUPLICATE: 'SET_IS_DUPLICATE'
}
