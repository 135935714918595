import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
import { adTypes } from '@clickadilla/components/constants/ad-types.js'
import types from '@/store/adForm/common/types.js'
import gtmPush from '@/services/utils/gtm-push.js'
import Ads from '@/services/classes/Ads.js'
import ValidationError from '@/services/classes/validation-error.js'
import { showErrorNotification } from '@/plugins/global-methods.js'
import handleErrors from '@/services/handleErrors.js'
import { adsRepository } from '@/services/repository-factory.js'

const actions = {
  setAdId({ commit }, adId) {
    commit(types.SET_AD_ID, adId)
  },
  setAdFormatType({ commit }, adFormatType) {
    commit(types.SET_AD_FORMAT_TYPE, adFormatType)
  },
  setName({ commit }, name) {
    commit(types.SET_NAME, name)
  },
  setBrand({ commit }, brand) {
    commit(types.SET_BRAND, brand)
  },
  setUrl({ commit }, url) {
    commit(types.SET_URL, url)
  },
  setSize({ commit }, size) {
    commit(types.SET_SIZE, size)
  },
  setCode({ commit }, code) {
    commit(types.SET_CODE, code)
  },
  setRotationType({ commit, dispatch }, rotationType) {
    commit(types.SET_ROTATION_TYPE, rotationType)
    dispatch('removeError', 'rotation_type')
  },
  setType({ commit }, type) {
    commit(types.SET_TYPE, type)
  },
  setSkipTime({ commit }, skipTime) {
    commit(types.SET_SKIP_TIME, skipTime)
  },
  setCommonUrl({ commit }, commonUrl) {
    commit(types.SET_COMMON_URL, commonUrl)
  },
  setHoursThreshold({ commit }, hoursThreshold) {
    commit(types.SET_HOURS_THRESHOLD, hoursThreshold)
  },
  setImpressionsThreshold({ commit }, impressionsThreshold) {
    commit(types.SET_IMPRESSIONS_THRESHOLD, impressionsThreshold)
  },
  setCreatives({ commit }, creatives) {
    commit(types.SET_CREATIVES, creatives)
  },
  setTitle({ commit }, title) {
    commit(types.SET_TITLE, title)
  },
  setIcon({ commit }, icon) {
    commit(types.SET_ICON, icon)
  },
  setContentFeedName({ commit }, contentFeedName) {
    commit(types.SET_CONTENT_FEED_NAME, contentFeedName)
  },
  setContentFeedUrl({ commit }, contentFeedUrl) {
    commit(types.SET_CONTENT_FEED_URL, contentFeedUrl)
  },
  setProviderWebsite({ commit }, providerWebsite) {
    commit(types.SET_PROVIDER_WEBSITE, providerWebsite)
  },
  setIsDuplicate({ commit }, isDuplicate) {
    commit(types.SET_IS_DUPLICATE, isDuplicate)
  },
  async fetchAdFormatsTokens({ commit }) {
    try {
      const creativeTokens = await adsRepository.creativeTokens()
      commit(types.SET_CREATIVE_TOKENS, creativeTokens)
    } catch (error) {
      handleErrors(error)
    }
  },
  addCreative({ commit, state }) {
    const creatives = JSON.parse(JSON.stringify(state.creatives))
    creatives.push({
      uuid: Math.random(),
      url: '',
      image: null,
      package: null,
      title: '',
      body: '',
      icon: null,
      autoGeneratedCreative: {},
      description: '',
      priority: state.defaultPriority,
      isActive: true,
      startingEventEndpoint: '',
      video: null,
      motionBannerImage: null,
      vastTagUrl: '',
      buttonOne: '',
      buttonTwo: '',
      motionBannerParams: {
        titleText: '',
        buttonText: '',
        descriptionText: '',
        additionalText: '',
        buttonTextColor: '#FFFFFF',
        faderColor: '#777777',
        textColor: '#FFFFFF',
        buttonColor: '#000000',
        buttonHoverColor: '#000000',
        buttonTextHoverColor: '#FFFFFF'
      },
      inPageSkin: {},
      inPageSkinGroup: {}
    })
    commit(types.SET_CREATIVES, creatives)
  },
  removeCreative({ commit, state }, removingCreative) {
    const filteredCreatives = state.creatives.filter((creative) => creative !== removingCreative)
    commit(types.SET_CREATIVES, filteredCreatives)
  },
  setMotionBannerParams({ commit }, parameters) {
    commit(types.SET_MOTION_BANNER_PARAMS, parameters)
  },
  removeError({ commit }, errorName) {
    if (Array.isArray(errorName)) {
      errorName.forEach((name) => {
        commit(types.SET_ERRORS_NAME, name, [])
      })
      return
    }
    commit(types.SET_ERRORS_NAME, errorName, [])
  },
  async validateKeywords({ commit, getters }) {
    const validationMessage = 'Creative contains words that can significantly reduce the amount of traffic'
    try {
      const validationResults = await adsRepository.validateKeywords(getters.getAdFormRequest)

      const validationWarnings = Object.entries(validationResults).reduce((acc, [key, value]) => {
        acc[key] = value.contains_keywords ? [validationMessage] : null
        return acc
      }, {})
      commit(types.SET_VALIDATION_WARNINGS, validationWarnings)
    } catch (error) {
      handleErrors(error)
    }
  },
  async saveForm({ commit, state }, params) {
    commit(types.SET_SAVE_IS_LOADING, true)
    const method = state.adId && !state.isDuplicate ? 'update' : 'store'
    try {
      const { data } = await adsRepository[method](params, state.adId)

      if (method === 'store' && params.type === adTypes.LANDING) {
        gtmPush({
          event: GTM_EVENTS.GENERATE_LINK_CREATIVE,
          event_category: GTM_EVENTS_CATEGORY.CREATED_AD
        })
      }

      gtmPush({ event: GTM_EVENTS.AD_CREATED_SUCCESSFULLY })
      return new Ads(data)
    } catch (error) {
      const adError = Ads.responseAdError(error)
      const validationErrors = error?.response?.data?.errors
      if (validationErrors) {
        const parsedFieldErrors = Object.entries(validationErrors).reduce((acc, [key, [value]]) => {
          acc[key] = value
          return acc
        }, {
          event: GTM_EVENTS.CREATE_AD_ALL_ERRORS,
          selected_ad_format: params.ad_format_type
        })
        gtmPush(parsedFieldErrors)
      }
      if (adError instanceof ValidationError) {
        commit(types.SET_All_ERRORS, adError.messages)
      } else {
        showErrorNotification(error.message)
      }
      return null
    } finally {
      commit(types.SET_SAVE_IS_LOADING, false)
    }
  },
  setEditAd({ commit, rootState }, ad) {
    const impressionsThreshold = ad.model.rotation?.impressionsThreshold
      ?? rootState.settings.defaultRotationImpressionsThreshold

    const hoursThreshold = ad.model.rotation?.hoursThreshold ?? rootState.settings.defaultRotationHoursThreshold

    commit(types.SET_All_ERRORS, {})
    commit(types.SET_NAME, ad.name)
    commit(types.SET_BRAND, ad.model.brand)
    commit(types.SET_SIZE, ad.size)
    commit(types.SET_URL, ad.model.src)
    if (ad.model.url) {
      commit(types.SET_URL, ad.model.url)
    }

    commit(types.SET_IMPRESSIONS_THRESHOLD, impressionsThreshold)
    commit(types.SET_HOURS_THRESHOLD, hoursThreshold)

    commit(types.SET_CODE, ad.model.code)
    commit(types.SET_TYPE, ad.model.type)

    if (ad.model.rotation?.type) {
      commit(types.SET_ROTATION_TYPE, ad.model.rotation.type)
    }

    if (ad.model.creatives?.length) {
      const mappedCreatives = ad.model.creatives.map((creative) => ({
        uuid: Math.random(),
        url: creative.url,
        image: creative.image,
        package: creative.package,
        title: creative.title,
        body: creative.body,
        autoGeneratedCreative: {},
        icon: creative.icon,
        description: creative.description,
        startingEventEndpoint: creative.startingEventEndpoint,
        priority: creative.priority,
        isActive: creative.isActive,
        video: creative.video,
        motionBannerImage: creative.motionBannerImage,
        vastTagUri: creative.vastTagUri,
        motionBannerParams: creative.motionBannerParams,
        buttonOne: creative.buttonOne,
        buttonTwo: creative.buttonTwo,
        inPageSkin: creative.inPageSkin,
        inPageSkinGroup: creative.inPageSkin?.inPageSkinGroup
      }))
      commit(types.SET_CREATIVES, mappedCreatives)
    }
    commit(types.SET_SKIP_TIME, ad.model.skipTime)
    commit(types.SET_TITLE, ad.model.title)
    commit(types.SET_ICON, ad.model.icon)
    commit(types.SET_CONTENT_FEED_NAME, ad.model.contentFeedName)
    commit(types.SET_CONTENT_FEED_URL, ad.model.contentFeedUrl)
    commit(types.SET_PROVIDER_WEBSITE, ad.model.providerWebsite)
  },
  resetAdForm({
    commit, dispatch, state, rootState
  }) {
    dispatch('setIsDuplicate', false)
    commit(types.SET_NAME, '')
    commit(types.SET_BRAND, '')
    commit(types.SET_URL, '')
    commit(types.SET_COMMON_URL, '')
    commit(types.SET_SIZE, '')
    commit(types.SET_CODE, '')
    commit(types.SET_ROTATION_TYPE, '')
    commit(types.SET_TYPE, '')

    commit(types.SET_IMPRESSIONS_THRESHOLD, rootState.settings.defaultRotationImpressionsThreshold)
    commit(types.SET_HOURS_THRESHOLD, rootState.settings.defaultRotationHoursThreshold)

    commit(types.SET_AD_ID, null)
    commit(types.SET_All_ERRORS, {})
    commit(types.SET_CREATIVES, [
      {
        uuid: Math.random(),
        url: '',
        image: null,
        package: null,
        title: '',
        body: '',
        icon: null,
        autoGeneratedCreative: {},
        description: '',
        priority: state.defaultPriority,
        isActive: true,
        startingEventEndpoint: '',
        video: null,
        motionBannerImage: null,
        vastTagUri: '',
        buttonOne: '',
        buttonTwo: '',
        motionBannerParams: {
          titleText: '',
          buttonText: '',
          descriptionText: '',
          additionalText: '',
          buttonTextColor: '#FFFFFF',
          faderColor: '#777777',
          textColor: '#FFFFFF',
          buttonColor: '#000000',
          buttonHoverColor: '#000000',
          buttonTextHoverColor: '#FFFFFF'
        },
        inPageSkin: {},
        inPageSkinGroup: {}
      }
    ])
    commit(types.SET_SKIP_TIME, state.defaultSkipTime)
    commit(types.SET_TITLE, '')
    commit(types.SET_ICON, '')
    commit(types.SET_CONTENT_FEED_NAME, '')
    commit(types.SET_CONTENT_FEED_URL, '')
    commit(types.SET_PROVIDER_WEBSITE, '')
  }
}

export default actions
