export default class Spot {
  id = null
  tdsId = null

  constructor(data) {
    if (!data) return

    this.id = data.id
    this.tdsId = data.tds_id
  }
}
