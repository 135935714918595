import debounce from 'debounce'
import handleErrors from '@/services/handleErrors.js'
import { campaignsStatisticsRepository } from '@/services/repository-factory.js'
import calculateTotalStatistics from '@/services/utils/calculate-total-statistics.js'
import getCampaignStatisticsGroupByOptions from '@/services/utils/get-campaign-statistics-group-by-options.js'
import getInitialDates from '@/services/utils/get-initial-dates.js'

const totalCalcStrategy = {
  impressions: 'amount',
  cost: 'amount',
  clicks: 'amount',
  requests: 'amount',
  bids: 'amount',
  wins: 'amount',
  spend: 'amount',
  ctr: 'average',
  video_views: 'amount',
  payouts: 'amount',
  conversions: 'amount'
}

const filtersLabels = {
  impressions: 'Impressions',
  clicks: 'Clicks',
  ctr: 'Ctr',
  cost: 'Cost',
  video_views: 'Video views'
}

function initialState() {
  return {
    groupByOptions: getCampaignStatisticsGroupByOptions(),
    campaignsStatisticsIsLoading: false,
    tableOptions: {
      sortBy: ['date'],
      sortDesc: [true]
    },

    groupBy: 'date',
    dates: getInitialDates(-14),
    campaignStatuses: [],
    adFormatsIds: [],
    selectedCampaigns: [],

    statisticsItems: [],
    tablePerPagePagination: [15, 25, 50, -1]
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    selectedGroupByOption: (state) => {
      const selectedGroupByOption = getCampaignStatisticsGroupByOptions()[state.groupBy]

      selectedGroupByOption.filters = selectedGroupByOption.filters.reduce((acc, filterKey) => {
        acc.push({
          text: filtersLabels[filterKey],
          value: filterKey
        })
        return acc
      }, [])

      return selectedGroupByOption
    },
    totalStatistics: (state) => calculateTotalStatistics(state.statisticsItems, totalCalcStrategy),
    selectedAdFormats(state, getters, rootState) {
      return state.adFormatsIds.map((id) => rootState.settings.adFormats.find((adFormat) => adFormat.id === id))
    },
    selectedCampaignsIds(state) {
      return state.selectedCampaigns.map((campaign) => campaign.id)
    }
  },
  mutations: {
    SET_CAMPAIGNS_STATISTICS_IS_LOADING: (state, val) => {
      state.campaignsStatisticsIsLoading = val
    },
    SET_DATES: (state, val) => {
      state.dates = val
    },
    SET_CAMPAIGN_STATUSES: (state, val) => {
      state.campaignStatuses = val
    },
    SET_AD_FORMATS_IDS: (state, val) => {
      state.adFormatsIds = val
    },
    SET_SELECTED_CAMPAIGNS: (state, val) => {
      state.selectedCampaigns = val
    },
    SET_GROUP_BY: (state, val) => {
      state.groupBy = val
    },
    SET_STATISTICS_ITEMS: (state, val) => {
      state.statisticsItems = val
    },
    SET_TABLE_OPTIONS: (state, val) => {
      state.tableOptions = val
    }
  },
  actions: {
    setCampaignStatuses({ commit }, campaignStatuses) {
      commit('SET_CAMPAIGN_STATUSES', campaignStatuses)
    },
    setSelectedCampaigns({ commit }, selectedCampaigns) {
      commit('SET_SELECTED_CAMPAIGNS', selectedCampaigns)
    },
    setDates({ commit }, dates) {
      commit('SET_DATES', dates)
    },
    setTableOptions({ commit }, tableOptions) {
      commit('SET_TABLE_OPTIONS', tableOptions)
    },
    setAdFormatsIds({ commit }, adFormatsIds) {
      commit('SET_AD_FORMATS_IDS', adFormatsIds)
    },
    setGroupBy({ commit }, groupBy) {
      commit('SET_GROUP_BY', groupBy)
    },
    resetStatisticsItems({ commit }) {
      commit('SET_STATISTICS_ITEMS', [])
    },
    fetchStatistics: debounce(async ({ commit, state, getters }) => {
      commit('SET_CAMPAIGNS_STATISTICS_IS_LOADING', true)
      try {
        const response = await campaignsStatisticsRepository.list({
          ...state,
          selectedCampaigns: getters.selectedCampaignsIds,
          adFormats: getters.selectedAdFormats
        })
        const statistics = response.map((item) => ({
          ...item,

          ctr: parseFloat(((item.ctr ?? 0) * 100).toFixed(2))
        }))
        commit('SET_STATISTICS_ITEMS', statistics)
      } catch (error) {
        handleErrors(error)
      }
      commit('SET_CAMPAIGNS_STATISTICS_IS_LOADING', false)
    }, 700),
    reset({ state }) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    }
  }
}
