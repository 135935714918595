import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import guardSections from '@/types/guard-sections.js'
import { loadLanguageAsync } from '@/i18n.js'
import routeNames from '@/types/route-names.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: routeNames.LOGIN,
      component: () => import('@/views/Login/Index.vue'),
      meta: {
        auth: false,
        title: 'Sign in',
        description: 'All ad formats that you need: push, popunder, banner, video, in-page, native, Interstitial',
        layoutName: 'DefaultLayout',
        localeFiles: ['login.json']
      }
    },
    {
      path: '/register',
      name: routeNames.REGISTER,
      component: () => import('@/views/Register/Index.vue'),
      meta: {
        auth: false,
        title: 'Registration page',
        description: 'Next Generation Self-Serve Ad Network Platform',
        layoutName: 'DefaultLayout',
        localeFiles: ['register.json']
      }
    },
    {
      path: '/logout',
      name: routeNames.LOGOUT,
      component: () => import('@/views/Logout.vue'),
      meta: {
        auth: true,
        title: 'Logout',
        layoutName: 'DefaultLayout'
      }
    },
    {
      path: '/unsubscribe/:email',
      name: routeNames.UNSUBSCRIBE,
      component: () => import('@/views/UnsubscribePage.vue'),
      meta: {
        title: 'Unsubscribe',
        layoutName: 'DefaultLayout'
      }
    },
    {
      path: '/register/:userId/confirm/:code',
      name: routeNames.CONFIRM_EMAIL,
      component: () => import('@/views/ConfirmEmailPage.vue'),
      meta: {
        title: 'ConfirmEmail',
        layoutName: 'DefaultLayout'
      }
    },
    {
      path: '/impersonate',
      name: routeNames.IMPERSONATE,
      component: () => import('@/views/Impersonate.vue'),
      meta: {
        layoutName: 'DefaultLayout'
      }
    },

    {
      path: '/password/reset',
      name: routeNames.RESET_PASSWORD,
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        title: 'Reset password',
        layoutName: 'DefaultLayout'
      }
    },
    {
      path: '',
      name: routeNames.MAIN,
      component: () => import('@/views/Main.vue'),
      meta: {
        auth: true,
        layoutName: 'MainLayouts'
      }
    },
    {
      path: '/dashboard',
      name: routeNames.DASHBOARD,
      component: () => import('@/views/Dashboard/Index.vue'),
      meta: {
        auth: true,
        title: 'Dashboard',
        layoutName: 'MainLayouts',
        section: guardSections.DASHBOARD,
        localeFiles: ['dashboard.json']
      }
    },
    {
      path: '/notification',
      name: routeNames.NOTIFICATION,
      component: () => import('@/views/Notification/Index.vue'),
      meta: {
        auth: true,
        title: 'Notification',
        layoutName: 'MainLayouts',
        section: guardSections.NOTIFICATIONS,
        localeFiles: ['notification_bell.json']
      }
    },
    {
      path: '/personal',
      name: routeNames.PERSONAL,
      component: () => import('@/views/Personal/Index.vue'),
      meta: {
        auth: true,
        title: 'Personal',
        layoutName: 'MainLayouts',
        section: guardSections.PROFILE,
        localeFiles: ['personal.json']
      }
    },
    {
      path: '/verifications/:type',
      name: routeNames.VERIFICATIONS,
      component: () => import('@/views/Verifications/Index.vue'),
      meta: {
        auth: true,
        title: 'Verification request',
        layoutName: 'MainLayouts',
        localeFiles: ['verifications.json']
      }
    },
    {
      path: '/api',
      name: routeNames.API,
      component: () => import('@/views/Api/Index.vue'),
      meta: {
        auth: true,
        title: 'API',
        layoutName: 'MainLayouts',
        section: guardSections.API,
        localeFiles: ['api.json']
      }
    },
    {
      path: '/tracking',
      name: routeNames.TRACKING,
      component: () => import('@/views/Tracking/Index.vue'),
      meta: {
        auth: true,
        title: 'Tracking',
        layoutName: 'MainLayouts',
        section: guardSections.TRACKING,
        localeFiles: ['tracking.json']
      }
    },
    {
      path: '/ads',
      name: routeNames.ADS,
      component: () => import('@/views/Ads/Index.vue'),
      meta: {
        auth: true,
        title: 'Ads',
        layoutName: 'MainLayouts',
        section: guardSections.ADS,
        localeFiles: ['ads.json']
      }
    },
    {
      path: '/ads/create',
      name: routeNames.CREATE_AD,
      component: () => import('@/views/CreateAndEditAds/Index.vue'),
      meta: {
        auth: true,
        title: 'Create ad',
        layoutName: 'MainLayouts',
        section: guardSections.ADS
      }
    },
    {
      path: '/ads/:id',
      name: routeNames.AD,
      component: () => import('@/views/Ad/Index.vue'),
      meta: {
        auth: true,
        title: 'Ad',
        layoutName: 'MainLayouts',
        section: guardSections.ADS,
        localeFiles: ['ads.json']
      }
    },
    {
      path: '/ads/:id/duplicate',
      name: routeNames.DUPLICATE_AD,
      component: () => import('@/views/CreateAndEditAds/Index.vue'),
      meta: {
        auth: true,
        title: 'Duplicate ad',
        layoutName: 'MainLayouts',
        section: guardSections.ADS
      }
    },
    {
      path: '/ads/:id/edit',
      name: routeNames.EDIT_AD,
      component: () => import('@/views/CreateAndEditAds/Index.vue'),
      meta: {
        auth: true,
        title: 'Edit ad',
        layoutName: 'MainLayouts',
        section: guardSections.ADS
      }
    },
    {
      path: '/campaigns/create',
      name: routeNames.CREATE_CAMPAIGN,
      component: () => import('@/views/CampaignForm/Index.vue'),
      meta: {
        auth: true,
        title: 'Create campaign',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGNS,
        localeFiles: ['campaign_form.json']
      }
    },
    {
      path: '/campaigns/create-offer-campaign',
      name: routeNames.OFFER_CAMPAIGN,
      props: true,
      component: () => import('@/views/CampaignForm/Index.vue'),
      meta: {
        auth: true,
        title: 'Create offer campaign',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGN_OFFERS,
        localeFiles: ['campaign_form.json']
      }
    },
    {
      path: '/campaigns',
      name: routeNames.CAMPAIGNS,
      component: () => import('@/views/Campaigns/Index.vue'),
      meta: {
        auth: true,
        title: 'Campaigns',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGNS,
        localeFiles: ['campaign.json']
      }
    },
    {
      path: '/campaigns/:id',
      name: routeNames.CAMPAIGN,
      props: true,
      component: () => import('@/views/Campaign/Index.vue'),
      meta: {
        auth: true,
        title: 'Campaign',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGNS,
        localeFiles: ['campaign.json', 'ads.json']
      }
    },
    {
      path: '/campaigns/:id/edit',
      name: routeNames.EDIT_CAMPAIGN,
      props: true,
      component: () => import('@/views/CampaignForm/Index.vue'),
      meta: {
        auth: true,
        title: 'Edit campaign',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGNS,
        localeFiles: ['campaign_form.json']
      }
    },
    {
      path: '/campaigns/:id/duplicate',
      name: routeNames.DUPLICATE_CAMPAIGN,
      props: true,
      component: () => import('@/views/CampaignForm/Index.vue'),
      meta: {
        auth: true,
        title: 'Duplicate campaign',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGNS,
        localeFiles: ['campaign_form.json']
      }
    },
    {
      path: '/upsale/:campaignId',
      name: routeNames.UPSALE,
      component: () => import('@/views/Upsale/Index.vue'),
      meta: {
        auth: true,
        title: 'Upsale',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGN_OFFERS,
        localeFiles: ['upsale.json']
      }
    },
    {
      path: '/finances',
      name: routeNames.FINANCES,
      component: () => import('@/views/Finances/Index.vue'),
      meta: {
        auth: true,
        title: 'Finances',
        layoutName: 'MainLayouts',
        section: guardSections.FINANCES,
        localeFiles: ['finances.json']
      }
    },
    {
      path: '/rtb',
      name: routeNames.RTB,
      component: () => import('@/views/Rtb.vue'),
      meta: {
        auth: true,
        layoutName: 'MainLayouts',
        section: guardSections.RTB
      },
      children: [
        {
          path: 'endpoints',
          name: routeNames.RTB_ENDPOINTS,
          component: () => import('@/views/RtbEndpoints/Index.vue'),
          meta: {
            title: 'RTB Endpoints',
            layoutName: 'MainLayouts',
            localeFiles: ['rtb.json']
          }
        },
        {
          path: 'endpoints/create',
          name: routeNames.RTB_CREATE_ENDPOINT,
          component: () => import('@/views/RtbCreateEndpoint/Index.vue'),
          meta: {
            title: 'RTB Create endpoint',
            layoutName: 'MainLayouts',
            localeFiles: ['rtb.json']
          }
        },
        {
          path: 'endpoints/edit/:id',
          name: routeNames.RTB_EDIT_ENDPOINT,
          props: true,
          component: () => import('@/views/RtbCreateEndpoint/Index.vue'),
          meta: {
            title: 'RTB Edit endpoint',
            layoutName: 'MainLayouts',
            localeFiles: ['rtb.json']
          }
        },
        {
          path: 'network',
          name: routeNames.RTB_NETWORK,
          component: () => import('@/views/RtbNetwork.vue'),
          meta: {
            title: 'RTB Network status',
            layoutName: 'MainLayouts',
            localeFiles: ['rtb.json']
          }
        },
        {
          path: 'network/create',
          name: routeNames.RTB_CREATE_NETWORK,
          component: () => import('@/views/RtbCreateNetwork.vue'),
          meta: {
            title: 'RTB Create network',
            layoutName: 'MainLayouts',
            localeFiles: ['rtb.json']
          }
        }
      ]
    },
    {
      path: '/campaign-statistics',
      name: routeNames.CAMPAIGN_STATISTICS,
      component: () => import('@/views/CampaignsStatistics/Index.vue'),
      meta: {
        auth: true,
        title: 'Campaigns statistics',
        layoutName: 'MainLayouts',
        section: guardSections.CAMPAIGN_STATISTICS,
        localeFiles: ['campaign_statistics.json']
      }
    },
    {
      path: '/rtb-statistics',
      name: routeNames.RTB_STATISTICS,
      component: () => import('@/views/RtbStatistics/Index.vue'),
      meta: {
        auth: true,
        title: 'RTB statistics',
        layoutName: 'MainLayouts',
        section: guardSections.RTB_STATISTICS,
        localeFiles: ['rtb.json']
      }
    },
    {
      path: '/ssp-statistics',
      name: routeNames.SSP_STATISTICS,
      component: () => import('@/views/SspStatistics/Index.vue'),
      meta: {
        auth: true,
        title: 'SSP statistics',
        layoutName: 'MainLayouts',
        section: guardSections.SSP_STATISTICS,
        localeFiles: ['rtb.json']
      }
    },
    {
      path: '/traffic-chart',
      name: routeNames.TRAFFIC_CHART,
      component: () => import('@/views/TrafficChart/Index.vue'),
      meta: {
        auth: true,
        title: 'Traffic chart',
        layoutName: 'MainLayouts',
        section: guardSections.TRAFFIC_CHART,
        localeFiles: ['traffic_chart.json']
      }
    },
    {
      path: '/add-funds',
      name: routeNames.ADD_FUNDS,
      component: () => import('@/views/AddFunds/Index.vue'),
      meta: {
        auth: true,
        title: 'Add funds',
        layoutName: 'MainLayouts',
        section: guardSections.ADD_FUNDS,
        localeFiles: ['add_funds.json']
      }
    },
    {
      path: '/add-funds/success',
      name: routeNames.ADD_FUNDS_SUCCESS,
      component: () => import('@/views/AddFundsSuccess.vue'),
      meta: {
        auth: true,
        layoutName: 'MainLayouts',
        section: guardSections.ADD_FUNDS,
        localeFiles: ['add_funds.json']
      }
    },
    {
      path: '/add-funds/error',
      name: routeNames.ADD_FUNDS_ERROR,
      component: () => import('@/views/AddFundsError.vue'),
      meta: {
        auth: true,
        layoutName: 'MainLayouts',
        section: guardSections.ADD_FUNDS,
        localeFiles: ['add_funds.json']
      }
    },
    {
      path: '/registration-succeeded',
      name: routeNames.REGISTRATION_SUCCEEDED,
      component: () => import('@/views/RegistrationSucceededPage/Index.vue'),
      meta: {
        layoutName: 'EmptyLayout',
        section: guardSections.SUCCESSFUL_REGISTRATION,
        localeFiles: ['register.json']
      }
    },
    {
      path: '/providers',
      name: routeNames.PROVIDERS,
      component: () => import('@/views/Providers/Index.vue'),
      meta: {
        auth: true,
        title: 'Providers',
        layoutName: 'MainLayouts',
        section: guardSections.TRAFFIC_PROVIDERS,
        localeFiles: ['providers.json']
      }
    },
    {
      path: '/provider/create',
      name: routeNames.CREATE_PROVIDER,
      component: () => import('@/views/CreateProvider.vue'),
      meta: {
        auth: true,
        title: 'Create providers',
        layoutName: 'MainLayouts',
        section: guardSections.TRAFFIC_PROVIDERS,
        localeFiles: ['providers.json']
      }
    },
    {
      path: '/marketplace',
      name: routeNames.MARKETPLACE,
      component: () => import('@/views/Marketplace/Index.vue'),
      meta: {
        auth: true,
        title: 'Marketplace',
        layoutName: 'MainLayouts',
        section: guardSections.FLAT_DEALS,
        localeFiles: ['marketplace.json']
      }
    },
    {
      path: '/flat-deal/:id',
      name: routeNames.FLAT_DEAL,
      component: () => import('@/views/FlatDeal/Index.vue'),
      meta: {
        auth: true,
        title: 'FlatDeal',
        layoutName: 'MainLayouts',
        section: guardSections.FLAT_DEALS,
        localeFiles: ['marketplace.json']
      }
    },
    {
      path: '/flat-deal/:id/offer',
      name: routeNames.FLAT_DEAL_OFFER,
      component: () => import('@/views/FlatDeal/Offer/Index.vue'),
      meta: {
        auth: true,
        title: 'Direct Deal Offer',
        layoutName: 'EmptyLayout',
        section: guardSections.FLAT_DEALS,
        localeFiles: ['marketplace.json']
      }
    },
    {
      path: '/404',
      name: routeNames.NOT_FOUND,
      component: () => import('@/views/NotFound.vue'),
      meta: {
        title: 'Page not found',
        layoutName: 'DefaultLayout',
        localeFiles: ['page_404.json']
      }
    },
    {
      path: '*',
      redirect: { name: '404' }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name === routeNames.IMPERSONATE) {
    next()
    return
  }
  const auth = router.app.$auth
  if (auth.isAuth === false && !!auth.getToken()) {
    await auth.refresh()
  }

  if (auth.isAuth === true && !store.state.settings.applicationIsAuth) {
    await Promise.all([
      auth.fetchUser(),
      store.dispatch('settings/fetchSettings', auth.isAuth)
    ])
  }

  if (
    auth.isAuth === false
    && (store.state.settings.applicationIsAuth || !store.state.settings.applicationDataFetched)
  ) {
    await store.dispatch('settings/fetchSettings', auth.isAuth)
  }

  await store.dispatch('settings/setBackRouteName', from.name)
  if (auth.isAuth) {
    const nextRoutingPath = sessionStorage.getItem('next_routing_path')

    if (nextRoutingPath && nextRoutingPath !== to.fullPath) {
      next({ path: nextRoutingPath })
      return
    }
    sessionStorage.removeItem('next_routing_path')
  }

  if (![auth.isAuth, undefined].includes(to.meta.auth)) {
    if (!auth.isAuth) {
      sessionStorage.setItem('next_routing_path', to.fullPath)
    }
    next({ name: to.meta.auth ? routeNames.LOGIN : routeNames.MAIN })
    return
  }

  if (to.meta.section) {
    const sections = store.state.settings.adNetwork.guardSections
    const foundSection = sections.find(({ name }) => name === to.meta.section)
    if (!foundSection) {
      next({ name: routeNames.NOT_FOUND })
      return
    }
  }
  await loadLanguageAsync(localStorage.locale, to.meta.localeFiles)
  next()
})

export default router
