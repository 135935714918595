export default {
  SET_BROWSERS: 'SET_BROWSERS',
  SET_CARRIERS: 'SET_CARRIERS',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CATEGORY_GROUPS: 'SET_CATEGORY_GROUPS',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_DEVICE_MODELS: 'SET_DEVICE_MODELS',
  SET_IP2_LOCATION_TYPES: 'SET_IP2_LOCATION_TYPES',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_OPERATING_SYSTEMS: 'SET_OPERATING_SYSTEMS',
  SET_TRAFFIC_TYPES: 'SET_TRAFFIC_TYPES',
  SET_DEVICE_VENDORS: 'SET_DEVICE_VENDORS',
  SET_REGIONS: 'SET_REGIONS',
  SET_INTERESTS: 'SET_INTERESTS',
  SET_MESSENGERS: 'SET_MESSENGERS',
  SET_CONVERSION_TYPES: 'SET_CONVERSION_TYPES',
  SET_IN_PAGE_SKIN_GROUPS: 'SET_IN_PAGE_SKIN_GROUPS',
  SET_SPOTS: 'SET_SPOTS',

  SET_BROWSERS_ARE_LOADING: 'SET_BROWSERS_ARE_LOADING',
  SET_CARRIERS_ARE_LOADING: 'SET_CARRIERS_ARE_LOADING',
  SET_CATEGORIES_ARE_LOADING: 'SET_CATEGORIES_ARE_LOADING',
  SET_CATEGORY_GROUPS_ARE_LOADING: 'SET_CATEGORY_GROUPS_ARE_LOADING',
  SET_COUNTRIES_ARE_LOADING: 'SET_COUNTRIES_ARE_LOADING',
  SET_DEVICE_MODELS_ARE_LOADING: 'SET_DEVICE_MODELS_ARE_LOADING',
  SET_IP2_LOCATION_TYPES_ARE_LOADING: 'SET_IP2_LOCATION_TYPES_ARE_LOADING',
  SET_LANGUAGES_ARE_LOADING: 'SET_LANGUAGES_ARE_LOADING',
  SET_OPERATING_SYSTEMS_ARE_LOADING: 'SET_OPERATING_SYSTEMS_ARE_LOADING',
  SET_TRAFFIC_TYPES_ARE_LOADING: 'SET_TRAFFIC_TYPES_ARE_LOADING',
  SET_DEVICE_VENDORS_ARE_LOADING: 'SET_DEVICE_VENDORS_ARE_LOADING',
  SET_REGIONS_ARE_LOADING: 'SET_REGIONS_ARE_LOADING',
  SET_INTERESTS_ARE_LOADING: 'SET_INTERESTS_ARE_LOADING',
  SET_MESSENGERS_ARE_LOADING: 'SET_MESSENGERS_ARE_LOADING',
  SET_CONVERSION_TYPES_ARE_LOADING: 'SET_CONVERSION_TYPES_ARE_LOADING',
  SET_IN_PAGE_SKIN_GROUPS_ARE_LOADING: 'SET_IN_PAGE_SKIN_GROUPS_ARE_LOADING',
  SET_SPOTS_ARE_LOADING: 'SET_SPOTS_ARE_LOADING'
}
