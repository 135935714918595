import InPageSkinGroup from '@/services/classes/InPageSkinGroup.js'

const getters = {
  getCollectionsForCampaignForm(state) {
    return {
      browsers: state.browsers,
      carriers: state.carriers,
      categories: state.categories,
      categoryGroups: state.categoryGroups,
      countries: state.countries,
      deviceModels: state.deviceModels,
      ip2LocationTypes: state.ip2LocationTypes,
      languages: state.languages,
      operatingSystems: state.operatingSystems,
      trafficTypes: state.trafficTypes,
      deviceVendors: state.deviceVendors,
      interests: state.interests,
      conversionTypes: state.conversionTypes,
      spots: state.spots,

      carriersAreLoading: state.carriersAreLoading,
      categoriesAreLoading: state.categoriesAreLoading,
      categoryGroupsAreLoading: state.categoryGroupsAreLoading,
      countriesAreLoading: state.countriesAreLoading,
      deviceModelsAreLoading: state.deviceModelsAreLoading,
      ip2LocationTypesAreLoading: state.ip2LocationTypesAreLoading,
      languagesAreLoading: state.languagesAreLoading,
      operatingSystemsAreLoading: state.operatingSystemsAreLoading,
      trafficTypesAreLoading: state.trafficTypesAreLoading,
      deviceVendorsAreLoading: state.deviceVendorsAreLoading,
      interestsAreLoading: state.interestsAreLoading,
      conversionTypesAreLoading: state.conversionTypesAreLoading,
      spotsAreLoading: state.spotsAreLoading
    }
  },
  inPageSkinGroups(state) {
    return state.inPageSkinGroups.map((skinGroup) => new InPageSkinGroup(skinGroup))
  },
  defaultInPageSkin(state, getterss, rootState) {
    const skins = getterss.inPageSkinGroups.reduce((acc, group) => {
      acc.push(...group.inPageSkins)
      return acc
    }, [])
    return skins.find((skin) => skin.id === +rootState.settings.defaultInPageSkinId) ?? null
  }
}

export default getters
