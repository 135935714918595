var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',_vm._g(_vm._b({class:[
            'mx-3 mr-2 my-1 py-0 px-3',
            {
              'primary rounded': _vm.isDedicatedLink,
              'px-0 justify-center': _vm.isMiniSidebarShown,
              'pr-0': _vm.menuItem.isBeta && !_vm.isMiniSidebarShown,
              'mb-4': _vm.menuItem.isMajor,
              'mr-5': _vm.isDedicatedLink && !_vm.isMiniSidebarShown
            }
          ],attrs:{"exact":"","to":_vm.isPageComputed,"href":_vm.isReferenceComputed,"target":_vm.isTargetComputed},on:{"click":function($event){return _vm.$emit('item-click')}}},'v-list-item',_vm.isTooltipShown(attrs),false),_vm.isTooltipShown(on)),[(_vm.menuItem.icon)?_c('v-list-item-action',{class:['d-flex justify-center align-center', { 'mr-3': !_vm.isMiniSidebarShown }]},[_c('v-icon',{class:{ 'opacity-60': !hover && !_vm.isActiveLink && !_vm.isDedicatedLink },attrs:{"size":"18","color":(hover || _vm.isActiveLink) && !_vm.isDedicatedLink ? 'primary' : 'white'}},[_vm._v(" "+_vm._s(_vm.menuItem.icon)+" ")])],1):_vm._e(),_c('v-list-item-title',{class:[
              'd-flex align-center justify-space-between custom-title white--text',
              { 'opacity-60': !hover && !_vm.isActiveLink && !_vm.isDedicatedLink }
            ]},[_c('div',{class:[{ 'mx-auto': !_vm.isMiniSidebarShown && _vm.menuItem.isMajor }]},[_vm._v(" "+_vm._s(_vm.menuItem.text)+" "),(_vm.isUnreadNotifications && _vm.unreadNotifications)?_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(`+ ${_vm.unreadNotifications}`)+" ")]):_vm._e()]),(_vm.menuItem.isBeta)?_c('div',{staticClass:"text-caption text-uppercase font-weight-bold info white--text rounded ml-2 py-1 px-2"},[_vm._v(" BETA ")]):_vm._e(),(_vm.menuItem.isNew)?_c('div',{staticClass:"text-caption text-uppercase font-weight-bold info white--text rounded ml-2 py-1 px-2"},[_vm._v(" NEW ")]):_vm._e()])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.menuItem.text))])]),(_vm.menuItem.isDivided)?_c('divider',{staticClass:"ml-7 mr-8"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }