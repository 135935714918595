<template>
  <div>
    <v-tooltip right color="primary">
      <template #activator="{ on, attrs }">
        <!--TODO: сформировать читаемый v-bind для всех параметров и удалить лишние атрибуты-->
        <v-hover v-slot="{ hover }">
          <v-list-item
            exact
            :class="[
              'mx-3 mr-2 my-1 py-0 px-3',
              {
                'primary rounded': isDedicatedLink,
                'px-0 justify-center': isMiniSidebarShown,
                'pr-0': menuItem.isBeta && !isMiniSidebarShown,
                'mb-4': menuItem.isMajor,
                'mr-5': isDedicatedLink && !isMiniSidebarShown
              }
            ]"
            :to="isPageComputed"
            :href="isReferenceComputed"
            :target="isTargetComputed"
            v-bind="isTooltipShown(attrs)"
            v-on="isTooltipShown(on)"
            @click="$emit('item-click')"
          >
            <v-list-item-action
              v-if="menuItem.icon"
              :class="['d-flex justify-center align-center', { 'mr-3': !isMiniSidebarShown }]"
            >
              <v-icon
                size="18"
                :class="{ 'opacity-60': !hover && !isActiveLink && !isDedicatedLink }"
                :color="(hover || isActiveLink) && !isDedicatedLink ? 'primary' : 'white'"
              >
                {{ menuItem.icon }}
              </v-icon>
            </v-list-item-action>

            <v-list-item-title
              :class="[
                'd-flex align-center justify-space-between custom-title white--text',
                { 'opacity-60': !hover && !isActiveLink && !isDedicatedLink }
              ]"
            >
              <div :class="[{ 'mx-auto': !isMiniSidebarShown && menuItem.isMajor }]">
                {{ menuItem.text }}
                <span v-if="isUnreadNotifications && unreadNotifications" class="primary--text">
                  {{ `+ ${unreadNotifications}` }}
                </span>
              </div>
              <div
                v-if="menuItem.isBeta"
                class="text-caption text-uppercase font-weight-bold info white--text rounded ml-2 py-1 px-2"
              >
                BETA
              </div>
              <div
                v-if="menuItem.isNew"
                class="text-caption text-uppercase font-weight-bold info white--text rounded ml-2 py-1 px-2"
              >
                NEW
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-hover>
      </template>

      <span>{{ menuItem.text }}</span>
    </v-tooltip>
    <divider v-if="menuItem.isDivided" class="ml-7 mr-8" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Divider from '@/components/main/Sidebar/Divider.vue'

  export default {
    name: 'IterableSidebarItem',
    components: {
      Divider
    },
    props: {
      menuItem: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      ...mapState('settings', ['isMiniSidebarShown']),
      ...mapState('notification', ['unreadNotifications']),
      isDedicatedLink() {
        return this.menuItem.isDedicatedLink
      },
      isUnreadNotifications() {
        return this.menuItem.isUnreadNotifications
      },
      isActiveLink() {
        return this.menuItem.activeOn && this.menuItem.activeOn.includes(this.$route.name)
      },
      isTargetComputed() {
        return this.menuItem.href && '_blank'
      },
      isPageComputed() {
        if (this.menuItem.href) return ''

        return this.menuItem.page && { name: this.menuItem.page }
      },
      isReferenceComputed() {
        return this.menuItem.href
      },
      isTooltipShown() {
        return (paramTooltip) => this.isMiniSidebarShown && paramTooltip
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-list-item {
    min-height: 34px !important;

    &:before {
      background-color: transparent;
    }
    &__action,
    &__icon {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }
  .v-tooltip__content {
    font-size: 16px !important;
    padding: 10px !important;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -7px;
      width: 16px;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 22px solid transparent;
      border-right: 18px solid var(--v-primary-base);
      z-index: 8;
    }
  }

  .dedicated-link {
    background-color: var(--v-primary-base);
    border-radius: 4px;
  }
</style>
