import types from '@/store/collectionsModule/common/types.js'
import getCollectionsByType from '@/services/repositories/collections/index.js'
import { conversionTypesRepository, inPageSkinGroupsRepository } from '@/services/repository-factory.js'
import Spot from '@/services/classes/Spot.js'
import handleErrors from '@/services/handleErrors.js'

export const collectionTypes = {
  BROWSERS: 'browsers',
  CARRIERS: 'carriers',
  CATEGORIES: 'categories',
  CATEGORY_GROUPS: 'category-groups',
  COUNTRIES: 'countries',
  DEVICE_MODELS: 'device-models',
  DEVICE_VENDORS: 'device-vendors',
  IP_2_LOCATION_TYPES: 'ip2-location-types',
  LANGUAGE: 'language',
  OPERATING_SYSTEMS: 'operating-systems',
  TRAFFIC_TYPES: 'traffic-types',
  INTERESTS: 'interests',
  MESSENGERS: 'messengers',
  SPOTS: 'spots'
}

const actions = {
  fetchCollectionsForCampaignForm({ dispatch }) {
    dispatch('fetchBrowsersCollection')
    dispatch('fetchCarriersCollection')
    dispatch('fetchCategoriesCollection')
    dispatch('fetchCategoryGroupsCollection')
    dispatch('fetchCountriesCollection')
    dispatch('fetchDeviceModelsCollection')
    dispatch('fetchIpToLocationsTypesCollection')
    dispatch('fetchLanguageCollection')
    dispatch('fetchOperatingSystemsCollection')
    dispatch('fetchTrafficTypesCollection')
    dispatch('fetchDeviceVendorsCollection')
    dispatch('fetchInterestsCollection')
    dispatch('fetchConversionTypes')
    dispatch('fetchSpotsCollection')
  },
  async fetchConversionTypes({ commit, state }) {
    if (state.conversionTypes.length) return

    commit(types.SET_CONVERSION_TYPES_ARE_LOADING, true)
    try {
      const data = await conversionTypesRepository.list()
      commit(types.SET_CONVERSION_TYPES, data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_CONVERSION_TYPES_ARE_LOADING, false)
  },
  async fetchMessengersList({ commit, state }) {
    if (state.messengers.length) return

    commit(types.SET_MESSENGERS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.MESSENGERS)
      commit(types.SET_MESSENGERS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_MESSENGERS_ARE_LOADING, false)
  },
  async fetchBrowsersCollection({ commit, state }) {
    if (state.browsers.length) return

    commit(types.SET_BROWSERS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.BROWSERS)
      commit(types.SET_BROWSERS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_BROWSERS_ARE_LOADING, false)
  },
  async fetchSpotsCollection({ commit, state }) {
    if (state.spots.length) return

    commit(types.SET_SPOTS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.SPOTS)
      const spots = data?.data?.map((spot) => new Spot(spot)) || []
      commit(types.SET_SPOTS, spots)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_SPOTS_ARE_LOADING, false)
  },
  async fetchCarriersCollection({ commit, state }) {
    if (state.carriers.length) return

    commit(types.SET_CARRIERS_ARE_LOADING, false)
    try {
      const { data } = await getCollectionsByType(collectionTypes.CARRIERS)
      commit(types.SET_CARRIERS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_CARRIERS_ARE_LOADING, false)
  },
  async fetchCategoriesCollection({ commit, state }) {
    if (state.categories.length) return

    commit(types.SET_CATEGORIES_ARE_LOADING, false)
    try {
      const { data } = await getCollectionsByType(collectionTypes.CATEGORIES)
      commit(types.SET_CATEGORIES, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_CATEGORIES_ARE_LOADING, false)
  },
  async fetchCategoryGroupsCollection({ commit, state }) {
    if (state.categoryGroups.length) return

    commit(types.SET_CATEGORY_GROUPS_ARE_LOADING, false)
    try {
      const { data } = await getCollectionsByType(collectionTypes.CATEGORY_GROUPS)
      commit(types.SET_CATEGORY_GROUPS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_CATEGORY_GROUPS_ARE_LOADING, false)
  },
  async fetchCountriesCollection({ commit, state }) {
    if (state.countries.length) return

    commit(types.SET_COUNTRIES_ARE_LOADING, false)
    try {
      const { data } = await getCollectionsByType(collectionTypes.COUNTRIES)
      commit(types.SET_COUNTRIES, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_COUNTRIES_ARE_LOADING, false)
  },
  async fetchDeviceModelsCollection({ commit, state }) {
    if (state.deviceModels.length) return

    commit(types.SET_DEVICE_MODELS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.DEVICE_MODELS)
      commit(types.SET_DEVICE_MODELS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_DEVICE_MODELS_ARE_LOADING, false)
  },
  async fetchIpToLocationsTypesCollection({ commit, state }) {
    if (state.ip2LocationTypes.length) return

    commit(types.SET_IP2_LOCATION_TYPES_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.IP_2_LOCATION_TYPES)
      commit(types.SET_IP2_LOCATION_TYPES, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_IP2_LOCATION_TYPES_ARE_LOADING, false)
  },
  async fetchLanguageCollection({ commit, state }) {
    if (state.languages.length) return

    commit(types.SET_LANGUAGES_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.LANGUAGE)
      commit(types.SET_LANGUAGES, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_LANGUAGES_ARE_LOADING, false)
  },
  async fetchOperatingSystemsCollection({ commit, state }) {
    if (state.operatingSystems.length) return

    commit(types.SET_OPERATING_SYSTEMS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.OPERATING_SYSTEMS)
      commit(types.SET_OPERATING_SYSTEMS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_OPERATING_SYSTEMS_ARE_LOADING, false)
  },
  async fetchTrafficTypesCollection({ commit, state }) {
    if (state.trafficTypes.length) return

    commit(types.SET_TRAFFIC_TYPES_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.TRAFFIC_TYPES)
      commit(types.SET_TRAFFIC_TYPES, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_TRAFFIC_TYPES_ARE_LOADING, false)
  },
  async fetchDeviceVendorsCollection({ commit, state }) {
    if (state.deviceVendors.length) return

    commit(types.SET_DEVICE_VENDORS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.DEVICE_VENDORS)
      commit(types.SET_DEVICE_VENDORS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_DEVICE_VENDORS_ARE_LOADING, false)
  },
  async fetchInterestsCollection({ commit, state }) {
    if (state.interests.length) return

    commit(types.SET_INTERESTS_ARE_LOADING, true)
    try {
      const { data } = await getCollectionsByType(collectionTypes.INTERESTS)
      commit(types.SET_INTERESTS, data.data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_INTERESTS_ARE_LOADING, false)
  },
  async fetchInPageSkinGroups({ commit, state }) {
    if (state.inPageSkinGroups.length) return

    commit(types.SET_IN_PAGE_SKIN_GROUPS_ARE_LOADING, true)
    try {
      const data = await inPageSkinGroupsRepository.index()
      commit(types.SET_IN_PAGE_SKIN_GROUPS, data)
    } catch (error) {
      handleErrors(error)
    }
    commit(types.SET_IN_PAGE_SKIN_GROUPS_ARE_LOADING, false)
  }
}

export default actions
