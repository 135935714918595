import Media from '@/services/classes/Media.js'
import MotionBanner from '@/services/classes/MotionBanner.js'

export default class InStreamCreative {
  // TODO rename startingEventEndpoint
  startingEventEndpoint = ''
  id = null
  isActive = true
  // TODO refactor motionBanner (motionBannerImage, motionBannerParams)
  motionBannerImage = new Media()
  motionBannerParams = new MotionBanner()
  priority = null
  url = ''
  vastTagUri = ''
  video = new Media()

  constructor(data) {
    if (!data) return

    this.startingEventEndpoint = data.custom_starting_event_endpoint ?? ''
    this.id = data.id ?? null
    this.isActive = data.is_active ?? true
    this.motionBannerImage = new Media(data.motion_banner?.image)
    this.motionBannerParams = new MotionBanner(data.motion_banner?.params)
    this.priority = data.priority ?? null
    this.url = data.url ?? ''
    this.vastTagUri = data.vast_tag_uri ?? ''
    this.video = new Media(data.video)
  }
}
