export default {
  foundAdFormat(state, getters, rootState) {
    return rootState.settings.adFormats.find((adFormat) => adFormat.id === state.adFormatId)
  },
  updateIsAllowed(state, getters) {
    return !state.loading && !!getters.foundAdFormat && !!state.pricingModel
  },
  adFormatId(state) {
    return state.adFormatId
  },
  premiumSitesByFormat(state) {
    return state.premiumSitesByFormat
  },
  pricingModels(state, getters, rootState) {
    if (getters.foundAdFormat) {
      const { trafficChartPricingModels } = rootState.settings
      const pricingModelsByAdFormat = getters.foundAdFormat.pricingModels
      return rootState.campaigns.pricingModels.filter(
        ({ value }) => pricingModelsByAdFormat.includes(value) && trafficChartPricingModels.includes(value)
      )
    }
    return []
  },
  trafficChartPresets(state, getters, rootState) {
    const { trafficTypes, countries, operatingSystems } = rootState.collections
    return {
      selectedAdFormat: getters.foundAdFormat,
      pricingModel: state.pricingModel,
      selectedCountries: countries.filter((country) => state.countriesIds.includes(country.id)),
      selectedTrafficType: trafficTypes.find((type) => state.trafficTypeId === type.id) || null,
      selectedOperatingSystems: state.operatingSystemsIds.map((id) => ({
        ...operatingSystems.find((system) => system.id === id),
        version: null
      })),
      isPremiumSites: !!state.isPremiumSites,
      premiumSitesIds: state.premiumSitesIds
    }
  }
}
