const notificationEventTypes = {
  CAMPAIGN_PAUSED_ACTIVATED: 'campaign.pausedActivated',
  CAMPAIGN_ACTIVATED: 'campaign.activated',
  CAMPAIGN_APPROVED: 'campaign.approved',
  CAMPAIGN_REJECTED: 'campaign.rejected',
  CAMPAIGN_LIMITS_BELOW_MIN: 'campaign.limitsBelowMin',
  CAMPAIGN_FIRST_NEVER_PAID: 'campaign.firstNeverPaid',
  CAMPAIGN_UNPROFITABLE_PAUSED: 'campaign.unprofitablePaused',
  CAMPAIGN_LOW_PRICE: 'campaign.lowPrice',
  CAMPAIGN_HAS_TOOLTIP: 'campaign.hasTooltip',
  USER_LOW_BALANCE: 'user.lowBalance',
  USER_BALANCE_EXPIRING: 'user.balanceExpiring',
  USER_NO_CREDIT_PAYMENTS: 'user.noCreditPayments',
  USER_NO_CAMPAIGN_CREATED: 'user.noCampaignCreated',
  USER_EMAIL_HAS_CHANGED: 'user.emailHasChanged',
  USER_FROZEN: 'user.frozen',
  VERIFICATION_APPROVED: 'verification.approved',
  VERIFICATION_CREATED: 'verification.created',
  VERIFICATION_REJECTED: 'verification.rejected',
  AD_APPROVED: 'ad.approved',
  AD_REJECTED: 'ad.rejected',
  APPROVABLE_NEEDS_EDITS: 'approvable.needsEdits',
  INVOICE_SECURIONPAY_CHARGE_UNCAPTURED: 'invoice.securionPayChargeUncaptured',
  TRAFFIC_PROVIDERS_SUBSCRIPTION_PAYMENT: 'trafficProviders.subscriptionPayment',
  PAYMENT_CREATED: 'payment.created'
}

const notificationCategoryTypes = {
  APPROVE_STATUS_CAMPAIGNS: 'Approve status campaigns',
  APPROVE_STATUS_AD: 'Approve status Ad',
  CAMPAIGN_STATUS: 'Campaign status',
  REPLENISHMENT_OF_FUNDS: 'Replenishment of funds',
  BALANCE: 'Balance',
  DEBIT: 'Debit',
  TOOLTIPS: 'Tooltips',
  LIMITS: 'Limits',
  ACCOUNT: 'Account'
}

const modelTypes = {
  CAMPAIGN: 'App\\Models\\Campaign',
  AD: 'App\\Models\\Ad'
}

export { modelTypes, notificationCategoryTypes, notificationEventTypes }
